<template>
  <div class="footer">
    <div
      v-if="showFooterScrollButton"
      v-show="this.$route.path === '/'"
      class="sp-contact"
    >
      <div class="scroll-button" v-scroll-to="'#contact'">
        応募・お問い合わせ
      </div>
    </div>
    <div class="footer-contents">
      <div class="footer-ira-imgs-sp sp-only">
        <img src="@/assets/ecocreen_top_ira008.webp" alt="大きい荷物を運ぶスタッフ" id="top_ira008_sp" class="top_ira008_sp">
        <div class="top_ira009-content">
          <img src="@/assets/ecocreen_top_ira009.webp" alt="トラックとスタッフ" id="top_ira009_sp" class="top_ira009_sp">
        </div>
      </div>
      <div class="footer-content">
        <img src="@/assets/ecocreen_logo2.webp" alt="エコクリーンサービス株式会社" class="footer-img">
        <p class="copyright">
          Copyright © ecs-kanai-group.com All Rights Reserved.
        </p>
      </div>
      <div class="footer-ira-imgs">
        <img src="@/assets/ecocreen_top_ira008.webp" alt="大きい荷物を運ぶスタッフ" id="top_ira008" class="top_ira008">
        <div class="top_ira009-content">
          <img src="@/assets/ecocreen_top_ira009.webp" alt="トラックとスタッフ" id="top_ira009" class="top_ira009">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutFooter",
  data() {
    return {
      showFooterScrollButton: false,
    };
  },
  mounted() {
    // スクロールイベントを監視
    window.addEventListener("scroll", this.handleScroll);
    this.initIntersectionObserver();
    this.initIntersectionObserver2();
    // 画面幅が767px以下の場合にのみ呼び出す
    if (window.innerWidth <= 767) {
      this.initIntersectionObserverSp();
      this.initIntersectionObserverSp2();
    }
  },
  beforeUnmount() {
    // コンポーネントが破棄される前にイベントリスナーを削除
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // ページのスクロール位置を取得
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // スクロール位置が一定値以上の場合にスクロールボタンを表示
      this.showFooterScrollButton = scrollTop > 200;

      // 特定の要素までスクロールしたかどうかチェック
      const specialElement = document.getElementById("contact"); // 特定の要素のIDを指定する
      if (specialElement) {
        const bounding = specialElement.getBoundingClientRect();
        // 特定の要素の上端が画面内に入った場合、ボタンを非表示
        if (bounding.top >= window.innerHeight) {
          // no action
        } else {
          this.showFooterScrollButton = false;
        }
      } else {
        // no action
      }
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira008');
      targets.forEach(target => observer.observe(target));
    },
    initIntersectionObserver2() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira009');
      targets.forEach(target => observer.observe(target));
    },
    initIntersectionObserverSp() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira008_sp');
      targets.forEach(target => observer.observe(target));
    },
    initIntersectionObserverSp2() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira009_sp');
      targets.forEach(target => observer.observe(target));
    },
  },
};
</script>

<style lang="scss">
.sp-contact {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 60px;
  z-index: 5;
  display: none;
  .scroll-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    padding: 1% 0;
    color: var(--white);
    background-color: var(--black);
    border: 1px solid var(--white);
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.footer {
  height: 280px;
  @media screen and (max-width: 767px) {
    height: 350px;
  }
  .footer-contents {
    height: 280px;
    padding-top: 120px;
    position: relative;
    background-color: var(--main);
    @media screen and (max-width: 767px) {
      height: 350px;
      padding-top: 270px;
    }
    .footer-ira-imgs-sp {
      width: 100%;
      position: absolute;
      top: 30px;
      left: 0;
      .top_ira008_sp,
      .top_ira009_sp {
        /* アニメーション用スタイル */
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      }
      .top_ira008_sp {
        width: 220px;
        margin-right: 2%;
        margin-left: auto;
        margin-bottom: 10px;
      }
      .top_ira009_sp {
        width: 250px;
        margin-right: auto;
        margin-left: 2%;
      }
    }
    .footer-content {
      width: 40%;
      height: 160px;
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 80px;
      }
      .footer-img {
        width: 575px;
        margin: auto auto 20px 40px;
        @media screen and (max-width: 1100px) {
          margin: 0 auto 0 20px;
        }
        @media screen and (max-width: 767px) {
          width: 270px;
          margin: 0 auto 10px auto;
        }
      }
      .copyright {
        color: var(--white);
        width: 70%;
        margin: 0 auto 0 60px;
        @media screen and (max-width: 1100px) {
          width: 90%;
          font-size: 10px;
          margin: 0 auto 0 30px;
        }
        @media screen and (max-width: 767px) {
          width: 90%;
          font-size: 12px;
          text-align: center;
          margin: 0 auto 0 auto;
        }
      }
    }
    .footer-ira-imgs {
      width: 55%;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      @media screen and (max-width: 767px) {
        display: none;
      }
      .top_ira008 {
        width: 40%;
        padding-bottom: 20px;
        /* アニメーション用スタイル */
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        @media screen and (max-width: 1100px) {
          width: 45%;
        }
      }
      .top_ira009-content {
        width: 35%;
        height: auto;
        padding-bottom: 20px;
        margin-left: 80px;
        display: flex;
        align-items: end;
        @media screen and (max-width: 1100px) {
          margin-left: 20px;
        }
        img {
          width: 100%;
          height: 90px;
          display: block;
          /* アニメーション用スタイル */
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        }
      }
    }
  }
}
</style>