<template>
  <div id="top" class="top">
    <div class="top-video">
      <video ref="player" autoplay muted playsinline loop>
        <source src="/video/ecocreen_top.mp4" poster="/img/ecocrean_top.webp" />
        このブラウザではサポートされていません。
      </video>
      <img src="@/assets/ecocreen_top_title.webp" alt="エコクリーンサービス株式会社 求人サイト" class="top-img pc-">
      <img src="@/assets/ecocreen_top_title_sp.webp" alt="エコクリーンサービス株式会社 求人サイト" class="top-img sp-only">
      <img src="@/assets/ecocreen_top_ira001.webp" id="top_ira001" class="top_ira001">
    </div>
  </div>
</template>

<script>
export default {
  name: "TopVue",
  mounted() {
    if (document.getElementById('top_ira001')) {
      this.initIntersectionObserver();
    }
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira001');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
.top {
  .top-video {
    position: relative;
    height: 100vh;
    @media screen and (max-width: 767px) {
      height: 90vh;
    }
    video {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 90%;
      height: 92vh;
      object-fit: cover;
      z-index: 0;
      @media screen and (max-width: 1100px) {
        width: 100%;
        height: 100%;
      }
    }
    .top-img {
      width: 40%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 75%;
      }
      @media screen and (max-width: 767px) {
        width: 90%;
      }
    }
    .top_ira001 {
      width: 6%;
      position: absolute;
      bottom: 0;
      left: 35%;
      z-index: 3;
      /* アニメーション用スタイル */
      opacity: 0;
      transform: scale(0.8);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        left: 65%;
        width: 15%;
      }
      @media screen and (max-width: 767px) {
        left: 78%;
        width: 20%;
      }
    }
  }
}
</style>
