<template>
  <section>
    <div class="wrap about-content">
      <p>
        現在、エコクリーンサービス株式会社では正社員のドライバーの募集しています。1日20～25件程度をドライバーとアシスタントスタッフ2人1組で担当し、一般のご家庭から出された粗大ごみを回収・積み込みをしていきます。免許取得支援制度を設けていますので、準中型自動車免許の運転免許があれば応募可能です。研修があるので未経験者でも安心して働けます。
      </p>
      <img src="@/assets/driver/ecocreen_driver_ira002.webp" alt="ドライバースタッフのイメージ" class="driver-ira002">
    </div>
  </section>
</template>

<script>
export default {
  name: "DriverAbout",
}
</script>

<style lang="scss" scoped>
section {
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 15vh 0;
  }
  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      display: block;
    }
    p {
      width: 68%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .driver-ira002 {
      width: 20%;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin: 20px auto 0 auto;
      }
    }
  }
}
</style>
