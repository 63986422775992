<template>
  <div class="card">
    <img :src="require(`@/assets/${imgPath}`)" :alt="imgName" class="card-img" />
    <div class="card-text">
      <h4 class="card-title">{{ title }}</h4>
      <p class="card-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardContent",
  props: {
    imgPath: String,
    imgName: String,
    title: String,
    description: String,
  }
}
</script>

<style lang="scss" scoped>
.card {
  .card-img {
    width: 100%;
  }
  .card-title {
    font-weight: 500;
    text-align: center;
    width: 95%;
    margin: 30px auto;
    color: var(--main);
    @media screen and (max-width: 767px) {
      font-weight: 700;
      margin: 15px auto;
    }
  }
}
</style>