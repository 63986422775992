<template>
  <section id="career">
    <div class="wrap">
      <div class="career-contents">
        <h1>
          エコクリーンサービス株式会社の
          <br>
          求人・採用について
        </h1>
        <p>
          エコクリーンサービス株式会社では現在、粗大ごみのドライバー・アシスタントスタッフの正社員を募集しております。1日20～25件程度をドライバーとアシスタントスタッフ2人1組で担当し、一般のご家庭から出された粗大ごみを回収・積み込みをしていきます。免許取得支援制度を設けていますので、普通車の運転免許があれば応募可能です。研修があるので未経験者でも安心して働けます。残業ほぼなし＋週休2日なので、プライベートも充実させたい方におすすめです。
        </p>
      </div>
      <img src="@/assets/ecocreen_top_ira002.webp" alt="大きなものを運ぶスタッフたち" id="top_ira002" class="top_ira002">
    </div>
  </section>
</template>

<script>
export default {
  name: "CareerVue",
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira002');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  padding: 160px 0;
  z-index: -1;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 10vh 0;
  }
  .wrap {
    position: relative;
    .career-contents {
      width: 60%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
      h1 {
        text-align: start;
        line-height: 2rem;
        margin-bottom: 30px;
        color: var(--main);
        @media screen and (max-width: 1100px) {
          text-align: center;
          margin-bottom: 15px;
        }
      }
      p {
        line-height: 1.8rem;
      }
    }
    .top_ira002 {
      position: absolute;
      width: 700px;
      @media screen and (min-width: 1101px) {
        top: -60px;
        right: -250px;
      }
      @media screen and (min-width: 1101px) and (max-width: 1600px) {
        top: 30px;
        right: 0;
        width: 38%;
      }
      @media screen and (max-width: 1100px) {
        width: 500px;
        margin: 20px auto 0 auto;
        position: inherit;
      }
      /* アニメーション用スタイル */
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }
  }
}
</style>
