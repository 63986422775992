<template>
  <AssistantRecruitTitle />
  <AssistantAboutVue />
  <RecommendedAudience />
  <WorkplaceOverview />
  <AssistantWork />
  <AssistantRecruit class="recruit-section" />
  <DailyWorkFlow />
  <Faq />
  <Contact />
</template>

<script>
import AssistantRecruitTitle from '@/components/Assistant/AssistantRecruitTitle.vue';
import AssistantAboutVue from '../components/Assistant/AssistantAbout.vue';
import RecommendedAudience from '@/components/Home/RecommendedAudience.vue';
import WorkplaceOverview from '@/components/Home/WorkplaceOverview.vue';
import AssistantWork from '../components/Assistant/AssistantWork.vue';
import AssistantRecruit from '@/components/Ui/AssistantRecruit.vue';
import DailyWorkFlow from '@/components/Home/DailyWorkFlow.vue';
import Faq from '@/components/Home/Faq.vue';
import Contact from '@/components/Home/Contact.vue';

export default {
  name: "AssistantVue",
  components: {
    AssistantRecruitTitle,
    AssistantAboutVue,
    RecommendedAudience,
    WorkplaceOverview,
    AssistantWork,
    AssistantRecruit,
    DailyWorkFlow,
    Faq,
    Contact,
  }
}
</script>

<style lang="scss" scoped>
.recruit-section {
  margin: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin: 50px 0;
  }
  @media screen and (max-width: 767px) {
    margin: 50px 0;
  }
}
</style>