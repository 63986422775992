<template>
  <section>
    <div class="wrap">
      <div class="message-contents">
        <div class="message-content">
          <h2>代表からのメッセージ</h2>
          <p>
            私たちは一緒に働く仲間の【時間】【心の豊かさ】【それぞれの生活】を大切にしたいと考えています。
            <br>
            「未経験だけどドライバーを目指したい」「仕事とプライベートどっちもしっかり充実させたい」、このような想いを持つ方、大歓迎です。「年1回のお子さまの行事に参加したい」「今日だけご家族さまの病院に付き添わないといけない」など、どうしてもの用事がある場合もご相談ください。プライベート、仕事もどちらも大事にしたい人が安心して働ける会社づくりをしています。みなさまと一緒に働けることを楽しみにしています。
          </p>
          <img src="@/assets/ecocreen_top_daihyou.webp" alt="代表取締役 金井秀明" class="ceo-img sp-only">
          <div class="message-from">
            <p>エコクリーンサービス株式会社</p>
            <h3>代表取締役 金井 秀明</h3>
          </div>
        </div>
        <div class="message-img">
          <img src="@/assets/ecocreen_top_daihyou.webp" alt="代表取締役 金井秀明">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MessageVue",
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--main);
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .wrap {
    padding: 60px 80px;
    background-color: var(--white);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: auto;
      padding: 40px 30px;
      margin: 0 20px;
    }
    @media screen and (max-width: 767px) {
      width: auto;
      padding: 30px 20px;
      margin: 0 10px;
    }
    .message-contents {
      display: flex;
      justify-content: space-between;
      min-height: 320px;
      @media screen and (max-width: 1100px) {
        min-height: 620px;
        justify-content: start;
        flex-wrap: wrap-reverse;
      }
      .message-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          display: none;
          width: 100%;
        }
        img {
          display: block;
          width: 100%;
          @media screen and (min-width: 768px) and (max-width: 1100px) {
            width: 50%;
          }
        }
      }
      .message-content {
        width: 72%;
        min-height: 320px;
        position: relative;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          width: 100%;
          min-height: 290px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          min-height: 400px;
        }
        h2 {
          text-align: start;
          color: var(--main);
          margin-bottom: 20px;
          @media screen and (max-width: 1100px) {
            text-align: center;
            margin-top: 20px;
          }
        }
        p {
          line-height: 1.3rem;
        }
        .ceo-img {
          margin: 15px 25%;
          width: 50%;
        }
        .message-from {
          position: absolute;
          bottom: 0;
          left: 0;
          @media screen and (max-width: 767px) {
            position: static;
          }
          p {
            @media screen and (max-width: 767px) {
              font-weight: 500;
              font-size: 16px;
              text-align: center;
            }
          }
          h3 {
            @media screen and (max-width: 767px) {
              font-weight: 500;
              font-size: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>