<template>
  <section>
    <h2>
      よくある質問
      <hr>
    </h2>
    <div class="sub-title bgc-main">
      <div class="sub-title-content">
        <p class="question">Q</p><h3>報告書などのパソコンを使用した業務はありますか？</h3>
      </div>
    </div>
    <div class="wrap">
      <p class="answer">パソコンの使用はありません。</p>
    </div>
    <div class="sub-title bgc-sub1">
      <div class="sub-title-content">
        <p class="question">Q</p><h3>車内は禁煙ですか？</h3>
      </div>
    </div>
    <div class="wrap">
      <p class="answer">お昼休憩をとる施設のみ禁煙です。</p>
    </div>
    <div class="sub-title bgc-main">
      <div class="sub-title-content">
        <p class="question">Q</p><h3>AT限定の普通免許しか持っていませんが応募できますか？</h3>
      </div>
    </div>
    <div class="wrap">
      <p class="answer">応募可能です！まずは、AT限定をはずすところから一緒に頑張りましょう！</p>
    </div>
  </section>
</template>
<script>

export default {
  name: "FaqVue"
}
</script>

<style lang="scss" scoped>
section {
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .sub-title {
    @media screen and (max-width: 1100px) {
      padding: 20px;
      margin: 0;
    }
    .sub-title-content {
      display: flex;
      width: 100%;
      max-width: 1100px;
      .question {
        font-size: 30px;
        padding: 0 20px;
        margin-right: 20px;
        color: var(--white);
        border-right: 1px solid var(--white);
        border-left: 1px solid var(--white);
        @media screen and (max-width: 1100px) {
          height: 40px;
          padding-top: 5px;
        }
      }
      h3 {
        width: auto;
        line-height: 1.3rem;
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          font-size: 20px;
          line-height: 1.4rem;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 1.4rem;
        }
      }
    }
  }
  .answer {
    margin: 30px;
  }
  .bgc-main {
    background-color: var(--main);
  }
  .bgc-sub1 {
    background-color: var(--sub1);
  }
}
</style>
