<template>
  <div>
    <div class="flow">
      <div class="flow-title">
        <h3>{{ title }}</h3>
      </div>
      <div class="flow-content">
        <p>{{ content }}</p>
      </div>
    </div>
    <div v-if="arrowFlg" class="flow-arrow">
      <img src="@/assets/20240508ecocreen_top_trai.webp" alt="次のステップ">
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowContent",
  props: {
    title: String,
    contentTitle: String,
    content: String,
    arrowFlg: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.flow {
  display: flex;
  justify-content: space-between;
  .flow-title {
    width: 20%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main);
    @media screen and (max-width: 767px) {
      width: 30%;
    }
    h3 {
      font-size: 23px;
      font-weight: 500;
      color: var(--white);
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .flow-content {
    width: 77%;
    min-height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    @media screen and (max-width: 767px) {
      display: block;
      width: 67%;
      min-height: 45px;
    }
    h4 {
      font-weight: 500;
      color: var(--main);
      text-align: start;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 1.5rem;
        height: auto;
      }
    }
  }
}
.flow-arrow {
  width: 20%;
  text-align: center;
  margin: 5px 0;
  @media screen and (max-width: 767px) {
    width: 30%;
    margin-bottom: 10px;
  }
  img {
    width: 30%;
    margin: auto;
    @media screen and (max-width: 767px) {
      width: 45%;
      margin: 10px auto;
    }
  }
}
</style>
