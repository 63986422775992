<template>
  <section>
    <div class="wrap">
      <h2>
        エコクリーンサービスの求人は
        <br class="sp-only">
        こんな人におすすめ
        <hr>
      </h2>
      <div id="recommend" class="content recommend-list">
        <CheckContent title="仕事も大事だけど、プライベートも大事にしたい" />
        <CheckContent title="週末と平日の夜の時間をしっかり確保したい" />
        <CheckContent title="未経験から新しい仕事にチャレンジしたい" />
        <CheckContent title="同じ職場で安定して長く働きたい" />
        <CheckContent title="毎年、昇給と賞与がある会社で働きたい" />
        <CheckContent title="仕事の頑張りが評価される会社で働きたい" />
        <CheckContent title="運転するのが好き" />
        <img src="@/assets/ecocreen_top_ira003.webp" alt="一人で作業するスタッフ" id="top_ira004" class="top_ira004">
      </div>
    </div>
  </section>
</template>

<script>
import CheckContent from '@/components/Ui/CheckContent.vue'

export default {
  name: "RecommendedAudience",
  components: {
    CheckContent,
  },
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira004');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub1);
  @media screen and (max-width: 767px) {
    padding-top: 15vh;
    padding-bottom: 17vh;
  }
  .wrap {
    .recommend-list {
      position: relative;
      .top_ira004 {
        width: 200px;
        position: absolute;
        right: -220px;
        bottom: 0;
        /* アニメーション用スタイル */
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 0.7s ease-out, transform 0.7s ease-out;
        @media screen and (max-width: 1100px) {
          width: 150px;
          right: 0;
          bottom: -100px;
        }
      }
    }
  }
}
</style>
