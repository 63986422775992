<template>
  <CompanyInfoPageTitle />
  <CompanyInfomation />
  <Map />
</template>

<script>
import CompanyInfoPageTitle from '@/components/Info/CompanyInfoPageTitle.vue';
import CompanyInfomation from '@/components/Info/CompanyInfomation.vue';
import Map from "@/components/Info/Map.vue";

export default {
  name: "InfoVue",
  components: {
    CompanyInfoPageTitle,
    CompanyInfomation,
    Map,
  }
}
</script>

<style lang="scss" scoped>
</style>