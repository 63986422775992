<template>
  <DriverRecruitTitle />
  <DriverAboutVue />
  <RecommendedAudience />
  <WorkplaceOverview />
  <DriverWork />
  <DriverRecruit class="recruit-section" />
  <DailyWorkFlow />
  <Faq />
  <Contact />
</template>

<script>
import DriverRecruitTitle from '@/components/Driver/DriverRecruitTitle.vue';
import DriverAboutVue from '../components/Driver/DriverAbout.vue';
import RecommendedAudience from '@/components/Home/RecommendedAudience.vue';
import WorkplaceOverview from '@/components/Home/WorkplaceOverview.vue';
import DriverWork from '../components/Driver/DriverWork.vue';
import DriverRecruit from '@/components/Ui/DriverRecruit.vue';
import DailyWorkFlow from '@/components/Home/DailyWorkFlow.vue';
import Faq from '@/components/Home/Faq.vue';
import Contact from '@/components/Home/Contact.vue';

export default {
  name: "DriverVue",
  components: {
    DriverRecruitTitle,
    DriverAboutVue,
    RecommendedAudience,
    WorkplaceOverview,
    DriverWork,
    DriverRecruit,
    DailyWorkFlow,
    Faq,
    Contact,
  },
}
</script>

<style lang="scss" scoped>
.recruit-section {
  margin: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    margin: 50px 0;
  }
  @media screen and (max-width: 767px) {
    margin: 50px 0;
  }
}
</style>