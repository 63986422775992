<template>
  <section>
    <h2>
      応募から入社の流れ
      <hr>
    </h2>
    <div class="wrap">
      <p class="entry-flow-description">
        現在の会社に勤めながら、当社の面接を受けられるように、夜や休日でも対応しています。
      </p>
      <StepContent
        title="STEP1"
        contentTitle="お問い合わせフォームから応募のご連絡"
        content="当サイトのお問い合わせフォーム（推奨）、または、お電話にて、求人に応募したい旨を弊社までご連絡ください。"
      />
      <StepContent
        title="STEP2"
        contentTitle="面接のご案内"
        content="面接を案内いたします。その際、履歴書(職務履歴書もあれば尚良)をご持参ください。"
      />
      <StepContent
        title="STEP3"
        contentTitle="面接"
        content="時間は30分から1時間程度を予定しています。即決採用の場合もございます。"
      />
      <StepContent
        title="STEP4"
        contentTitle="合否の連絡"
        content="合否の連絡をします。合格の場合は、勤務開始日を決定します。"
      />
      <StepContent
        title="STEP5"
        contentTitle="勤務開始"
        content="決定した勤務開始日から勤務となります。"
        :arrowFlg="false"
      />
    </div>
  </section>
</template>

<script>
import StepContent from '@/components/Ui/StepContent.vue';
export default {
  name: "EntryFlow",
  components: {
    StepContent,
  }
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    .entry-flow-description {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
</style>
