<template>
  <div class="simple-table-row">
    <div class="row-title">{{ title }}</div>
    <div v-if="telFlg" class="row-content"><a href="tel:0582315716">{{ content }}</a></div>
    <div v-else class="row-content">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: "SimpleTableRow",
  props: {
    title: String,
    content: String,
    tel: {
      type: String,
      default: "",
    },
    telFlg: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-table-row {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid var(--black);
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
  .row-title {
    width: 20%;
    line-height: 30px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      width: 30%;
    }
  }
  .row-content {
    width: 80%;
    line-height: 30px;
    border-left: 1px solid var(--black);
    padding-left: 5%;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      width: 70%;
      line-height: 1.5rem;
    }
    a {
      @media screen and (min-width: 768px) {
          // 現話番号のクリックを無効化する
          pointer-events: none;
          cursor: default;
        }
    }
  }
}
</style>
