<template>
  <section>
    <div class="wrap">
      <div class="content">
        <img src="@/assets/info/ecocreen_kaisya_01.webp" alt="エコクリーンサービス株式会社">
        <h2>会社情報</h2>
        <SimpleTableRow title="会社名" content="エコクリーンサービス株式会社" />
        <SimpleTableRow title="代表者名" content="金井 秀明" />
        <SimpleTableRow title="所在地" content="〒502-0931 岐阜県岐阜市則武2557番地8" />
        <SimpleTableRow title="電話番号" content="058-231-5716" :telFlg="true" tel="0582315716" />
        <SimpleTableRow title="FAX番号" content="058-231-5736" />
        <SimpleTableRow title="事業内容" content="廃棄物運搬業など。一般のご家庭から出された粗大ごみを回収しています。" />
        <SimpleTableRow title="資本金" content="300万円" />
      </div>
    </div>
  </section>
</template>

<script>
import SimpleTableRow from '../Ui/SimpleTableRow.vue';

export default {
  name: "CompanyInfomation",
  components: {
    SimpleTableRow,
  }
}
</script>

<style lang="scss" scoped>
section {
  padding: 100px 0;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    padding: 50px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 10vh 0;
  }
  .wrap {
    .content {
      h2 {
        line-height: 2rem;
        padding: 10px 0;
        margin-top: 40px;
        text-align: center;
        color: var(--white);
        background-color: var(--sub1);
      }
    }
  }
}
</style>