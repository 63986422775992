<template>
  <section>
    <div class="wrap">
      <h2>
        粗大ごみの収集のドライバーの<br class="sp-only">お仕事について
        <hr>
      </h2>
      <div class="work-content">
        <div class="work-img">
          <img class="" src="@/assets/info/ecocreen_kaisya_01.webp" alt="粗大ごみの収集トラック">
          <img id="worker-img" class="worker-img" src="@/assets/driver/ecocreen_driver_ira003.webp" alt="荷物を運ぶスタッフ">
        </div>
        <div class="work-descriptions">
          <div class="description job">
            <h3>仕事内容</h3>
            <p>
              一般のご家庭から出された粗大ごみを回収し、運搬するお仕事です。ゼンリン地図をたよりに、1日20～25件程度をアシスタントスタッフと2人1組で担当して、回収・積み込みをしていきます。戸別収集業務と搬送業務を1ヵ月ごとにローテーションを組んで作業しています。
              <br>
              ドライバーの方には現地までの運転をお願いしています。アシスタントスタッフが道案内やバック誘導のフォローをしてくれます。
            </p>
          </div>
          <div class="description skill">
            <h3>必要な資格・スキル</h3>
            <p>
              学歴・経歴・経験不問です。準中型自動車免許（平成19年6月1日までに普通免許を取得された方は保有しています）があれば、4t車の免許をお持ちでなくても大丈夫です！弊社は免許取得支援をしており、働きながら4t車の免許を取得できます。そのため、やる気があれば応募可能となっています。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "DriverWork",
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.worker-img');
      targets.forEach(target => observer.observe(target));
    }
  }
}
</script>
<style lang="scss" scoped>
section {
  background-color: var(--sub3);
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .wrap {
    .work-content {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .work-img {
        position: relative;
        width: 47%;
        aspect-ratio: 704 / 465;
        margin-bottom: auto;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .worker-img {
          width: 75%;
          position: absolute;
          bottom: -25%;
          left: -40%;
          /* アニメーション用スタイル */
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 0.5s ease-out, transform 0.5s ease-out;
          @media screen and (min-width: 1301px) and (max-width: 1550px) {
            left: -20%;
          }
          @media screen and (min-width: 768px) and (max-width: 1300px) {
            width: 300px;
            bottom: -60%;
            left: -0%;
          }
          @media screen and (max-width: 767px) {
            width: 80%;
            top: auto;
            right: auto;
            bottom: -130px;
            left: 10%;
          }
        }
      }
      .work-descriptions {        
        width: 47%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .description {
          h3 {
            color: var(--main);
            margin-bottom: 10px;
          }
        }
        .job {
          @media screen and (max-width: 767px) {
            margin-top: 130px;
          }
        }
        .skill {
          margin-top: 40px;
          @media screen and (max-width: 767px) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>