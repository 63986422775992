<template>
  <section class="title">
    <div class="wrap page-title">
      <h1>
        エコクリーンサービス株式会社は
        <br>
        廃棄物の運搬事業により、
        <br class="sp-only">
        循環型社会に貢献することを
        <br class="sp-only">
        目指しています。
      </h1>
    </div>
    <img src="@/assets/driver/ecocreen_driver_ira001.webp" alt="荷物を運ぶスタッフ" id="driver_ira001" class="ira001 pc-only">
    <img src="@/assets/driver/ecocreen_driver_ira001sp.webp" alt="荷物を運ぶスタッフ" id="driver_ira001sp" class="ira001sp sp-only">
  </section>
</template>

<script>
export default {
  name: "companyInfo",
  mounted() {
    this.initIntersectionObserverSp();
    this.initIntersectionObserver();
    console.error();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.ira001');
      targets.forEach(target => observer.observe(target));
    },
    initIntersectionObserverSp() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.ira001sp');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  padding: 0;
  background-color: var(--main);
  .page-title {
    height: 260px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 767px) {
      margin-top: 10vh;
      height: auto;
      padding: 40px 20px;
    }
    h1 {
      font-size: 30px;
      line-height: 3rem;
      color: var(--white);
      text-align: start;
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 2rem;
      }
    }
  }
  .ira001 {
    width: 25%;
    position: absolute;
    top: 20px;
    right: 3%;
    /* アニメーション用スタイル */
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    @media screen and (min-width: 1101px) and (max-width: 1850px) {
      width: 20%;
      top: 10px;
      right: 3%;
    }
  }
  .ira001sp {
    width: 350px;
    position: absolute;
    top: auto;
    right: 3%;
    bottom: -80px;
    /* アニメーション用スタイル */
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.7s ease-out, transform 0.7s ease-out;
  }
}
</style>