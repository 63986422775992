<template>
  <section>
    <h1>
      404 NotFound
    </h1>
    <div class="wrap">
      <p class="notfound">
        ご指定のページは存在しないか削除されています。
        <br>
        入力したページのURLをご確認ください。
      </p>
      <router-link to="/" class="top-btn">
        サイトTOPへ
      </router-link>
    </div>
    <img src="@/assets/ecocreen_top_ira009.webp" alt="お探しのページが見つかりませんでした。">
  </section>
</template>

<script>
export default {
  name: "NotFound",
}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  color: var(--main);
}
.notfound {
  text-align: center;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}
.top-btn {
  display: block;
  width: 30%;
  padding: 20px 0;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  color: var(--font);
  border: 1px solid var(--main);
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 40%;
  }
  &:hover {
    color: var(--white);
    background-color: var(--main);
  }
}
img {
  width: 40%;
  margin: 60px auto 65vh auto;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    width: 70%;
  }
}
</style>