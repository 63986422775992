<template>
  <section>
    <h2>
      こんな勤務条件・職場環境です
      <hr>
    </h2>
    <div class="sub-title">
      <h3>給与・報酬に関すること</h3>
      <img src="@/assets/ecocreen_top_ira004.webp" alt="オレンジ色のコーン" class="top_ira004">
    </div>
    <div class="wrap">
      <div class="description-contents">
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_001.webp"
          imgName="昇給・賞与の実績がございます"
          title="毎年昇給・賞与の実績あり"
          description="長く働いてくれる人を大事にしています。頑張っている人には、業績に応じて昇給・賞与が毎年あります。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_008.webp"
          imgName="作業着等は支給しています"
          title="作業着(冬場は上着も)・ヘルメット支給"
          description="作業着(冬場は上着も)・ヘルメットを支給していますので、お仕事着をわざわざ買う必要はありません。安全靴・手袋も必要ですが支給していますので安心です。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_002.webp"
          imgName="安全＋スキルがあると特別手当を支給しています"
          title="安全運転手当"
          description="安全＋スキルがあると認められると、運転手特別手当毎月2万円（前年度実績）を支給しています。"
        />
      </div>
    </div>
    <div class="sub-title">
      <h3>勤務時間・休日に関すること</h3>
    </div>
    <div class="wrap">
      <div class="description-contents">
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_003.webp"
          imgName="残業はほとんどございません"
          title="残業ほぼなし"
          description="交通状況（工事渋滞など）により多少前後する場合はありますが基本的に残業はありません。そのため、平日の夜の時間をしっかり確保できます。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_004.webp"
          imgName="基本的には土日休みで週休二日制です"
          title="基本土日祝日休みの週休二日制"
          description="土日祝日休みが基本です。2か月に1回程度、土曜日、日曜日の出勤がありますが、もちろん、平日に振替のお休みを用意しています！週末の時間もしっかり確保できます。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_005.webp"
          imgName="ご友人やご家族との予定が立てやすいです"
          title="年間カレンダー"
          description="４月に１年間のお仕事カレンダー(シフト表)が出ます。そのため、ご友人やご家族様との予定が立てやすいです。"
        />
      </div>
    </div>
    <div class="sub-title">
      <h3>キャリアサポート</h3>
    </div>
    <div class="wrap">
      <div class="description-contents">
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_009.webp"
          imgName="普通車免許があれば、応募可能です!"
          title="免許取得支援"
          description="普通車免許があれば、中型免許をお持ちでなくても応募可能です！働きながら中型車の免許をとることもできます。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_015.webp"
          imgName="多くの従業員が未経験から活躍しています！"
          title="未経験者 OK"
          description="入社時に研修があるので未経験者でも安心です。イチからやさしく丁寧に教えます。多くの従業員が未経験から活躍しています。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_011.webp"
          imgName="キャリアアップも可能です！"
          title="キャリアアップ"
          description="仕事を頑張ってくれる方には次のキャリアアップがあります。（アシスタントからドライバー登録へ、運転特別手当など）"
        />
      </div>
    </div>
    <div class="sub-title">
      <h3>健康と快適な職場環境</h3>
    </div>
    <div class="wrap">
      <div class="description-contents">
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_016.webp"
          imgName="年に1度健康診断がございます"
          title="健康診断あり"
          description="1年に1回、健康診断を行っています。自分の健康状態について意識でき、健康に良い生活習慣を身につけるきっかけになります。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_012.webp"
          imgName="定期的な車のメンテナンスで車内も快適です"
          title="定期的な車の洗車・整備で車内も快適"
          description="金曜日の午後は車両の整備・洗車時間となっていますので、お仕事で使うトラックは常に気持ちよく乗ることができます。"
        />
        <CardContent
          class="description-content"
          imgPath="ecocreen_top_017.webp"
          imgName="夏場は健康を考慮してタブレット支給しています"
          title="夏場は健康を考慮してタブレット支給"
          description="熱中症は時に生命を脅かすこともあります。社員の健康を守ることも会社としての役目です。"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CardContent from '@/components/Ui/CardContent.vue';

export default {
  name: "WorkplaceOverview",
  components: {
    CardContent,
  },
}
</script>

<style lang="scss" scoped>
section {
  @media screen and (max-width: 767px) {
    padding: 70px 0;
  }
  h2 {
    hr {
      margin-bottom: 40px;
    }
  }
  .sub-title {
    position: relative;
    .top_ira004 {
      width: 60px;
      height: 83px;
      position: absolute;
      top: -83px;
      left: 10%;
      @media screen and (max-width: 767px) {
        width: 50px;
        height: 68px;
        top: -68px;
        left: 2%;
      }
    }
  }
  .wrap {
    margin: 70px auto 100px auto;
    @media screen and (max-width: 767px) {
      margin: 35px auto 50px auto;
    }
    .description-contents {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .description-content {
        width: 30%;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>