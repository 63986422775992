<template>
  <section>
    <div class="wrap">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8040.015253009328!2d136.7290270380209!3d35.44966986672397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003ac6fd903f34b%3A0x5062c3011b82e785!2z44CSNTAyLTA5MzEg5bKQ6Zic55yM5bKQ6Zic5biC5YmH5q2m77yS77yV77yV77yX4oiS77yY!5e0!3m2!1sja!2sjp!4v1715668021410!5m2!1sja!2sjp" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <router-link to="/" id="recruit-content" class="recruit-content">
        <img class="recruit-btn sp-only" src="@/assets/info/ecocreen_kaisya_banner_sp.webp" alt="ドライバー・アシスタントスタッフ【正社員】の求人はこちらから">
        <img class="recruit-btn pc-tablet-only" src="@/assets/info/ecocreen_kaisya_banner.webp" alt="ドライバー・アシスタントスタッフ【正社員】の求人はこちらから">
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapVue",
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    recruit() {
      window.location.href = '/#recruit';
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.recruit-content');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  padding-top: 0;
  .wrap {
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    .recruit-content {
      display: flex;
      justify-content: center;
      /* アニメーション用スタイル */
      opacity: 0;
      transform: scale(0.9);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      .recruit-btn {
        width: 50%;
        margin: 20px auto 0 auto;
        background-color: #fff;
        border: 0;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
        @media screen and (max-width: 767px) {
          width: 80%;
        }
      }
    }
  }
}
</style>
