<template>
  <Top />
  <Career />
  <RecommendedAudience />
  <WorkplaceOverview />
  <AboutUs />
  <Message />
  <DailyWorkFlow />
  <WorkCommunity />
  <RecruitVue />
  <EntryFlow />
  <Faq />
  <Contact />
</template>

<script>
import Top from '@/components/Home/Top.vue';
import Career from '@/components/Home/Career.vue';
import RecommendedAudience from '@/components/Home/RecommendedAudience.vue';
import WorkplaceOverview from '@/components/Home/WorkplaceOverview.vue';
import AboutUs from '@/components/Home/AboutUs.vue';
import Message from '@/components/Home/Message.vue';
import DailyWorkFlow from '@/components/Home/DailyWorkFlow.vue';
import WorkCommunity  from '@/components/Home/WorkCommunity.vue';
import RecruitVue from '@/components/Home/Recruit.vue';
import EntryFlow from '@/components/Home/EntryFlow.vue';
import Faq from '@/components/Home/Faq.vue';
import Contact from '@/components/Home/Contact.vue';

export default {
  name: 'HomeView',
  components: {
    Top,
    Career,
    RecommendedAudience,
    WorkplaceOverview,
    AboutUs,
    Message,
    DailyWorkFlow,
    WorkCommunity,
    RecruitVue,
    EntryFlow,
    Faq,
    Contact,
  }
}
</script>
