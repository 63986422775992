<template>
  <section>
    <div class="wrap">
      <div class="content">
        <h3 class="occupation-name">求人条件の詳細</h3>
        <div class="simple-table">
          <SimpleTableRowVue title="雇用形態" content="正社員" />
          <SimpleTableRowVue title="勤務地" content="岐阜県岐阜市木田5丁目38番" />
          <SimpleTableRowVue title="勤務時間" content="08:00〜17:00（お昼休憩1時間あり）" />
          <SimpleTableRowVue title="休日" content="基本土日祝日休みです。2か月に1回程度、土曜日または日曜日の出勤日があります。(振替休日あり)" />
          <div class="simple-table-row">
            <div class="row-title">月給</div>
            <div class="row-content">
              200,000円〜220,000円 翌月10日払い
              <br>
              ■内訳例
              <br>
              基本給：104,000円〜124,000円
              <br>
              職務手当 90,000円〜90,000円
              <br>
              野外手当 6,000円〜6,000円
            </div>
          </div>
          <SimpleTableRowVue title="昇給" content="年1回あり（前年度実績：1,000円〜5,000円/月）" />
          <SimpleTableRowVue title="賞与" content="年1回あり（前年度実績：50,000円〜200,000円）" />
          <SimpleTableRowVue title="応募条件" content="普通免許(AT限定可)" />
          <SimpleTableRowVue title="通勤方法" content="マイカー通勤可" />
          <SimpleTableRowVue title="加入保険" content="雇用保険，労災保険，健康保険，厚生年金" />
          <SimpleTableRowVue title="試用期間" content="なし" />
          <div class="simple-table-row">
            <div class="row-title">待遇・福利厚生</div>
            <div class="row-content">
              ・退職金制度あり(3年以上)
              <br>
              ・産休、育休制度あり
              <br>
              ・介護休暇あり
              <br>
              ・年次有給休暇あり(10日)
              <br>
              ・健康診断あり
              <br>
              ・制服、保護具支給
              <br>
              ・備品手当あり(2,000円/月)
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SimpleTableRowVue from '@/components/Ui/SimpleTableRow.vue'

export default {
  name: "DriverRecruit",
  components: {
    SimpleTableRowVue
  }
}
</script>
<style lang="scss" scoped>
section {
  padding: 0;
  .wrap {
    .content {
      .occupation-name {
        text-align: center;
        padding: 10px 0;
        color: var(--white);
        background-color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .simple-table-row {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid var(--black);
        @media screen and (max-width: 767px) {
          padding: 10px 0;
        }
        .row-title {
          width: 20%;
          line-height: 30px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            width: 30%;
          }
        }
        .row-content {
          width: 80%;
          line-height: 30px;
          border-left: 1px solid var(--black);
          padding-left: 5%;
          @media screen and (max-width: 767px) {
            font-size: 14px;
            width: 70%;
            line-height: 1.5rem;
          }
          a {
            @media screen and (min-width: 768px) {
                // 現話番号のクリックを無効化する
                pointer-events: none;
                cursor: default;
              }
          }
        }
      }
    }
  }
}
</style>