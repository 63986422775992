<template>
  <section id="recruit">
    <h2 id="driver">
      募集中の職種
      <hr>
    </h2>
    <div class="wrap">
      <div class="content">
        <h3 class="occupation-name">ドライバー【正社員】（未経験 OK）</h3>
        <div class="simple-table">
          <SimpleTableRowVue title="雇用形態" content="正社員" />
          <SimpleTableRowVue title="勤務地" content="岐阜県岐阜市木田5丁目38番" />
          <SimpleTableRowVue title="勤務時間" content="08:00〜17:00" />
          <SimpleTableRowVue title="休日" content="土日祝日（一部第三土曜日は除く）" />
          <SimpleTableRowVue title="給与" content="月給 ￥240,000 ～ ￥260,000" />
        </div>
        <router-link to="/driver" id="assistant-staff" class="detail-btn">詳細を見る</router-link>
        <h3 class="occupation-name">
          アシスタントスタッフ【正社員】
          <br class="sp-only">
          （未経験 OK）
        </h3>
        <div class="simple-table">
          <SimpleTableRowVue title="雇用形態" content="正社員" />
          <SimpleTableRowVue title="勤務地" content="岐阜県岐阜市木田5丁目38番" />
          <SimpleTableRowVue title="勤務時間" content="08:00〜17:00" />
          <SimpleTableRowVue title="休日" content="土日祝日（一部第三土曜日は除く）" />
          <SimpleTableRowVue title="給与" content="月給 ￥200,000 ～ ￥220,000" />
        </div>
        <router-link to="/assistant" class="detail-btn mb-0">詳細を見る</router-link>
      </div>
    </div>
  </section>
</template>
<script>
import SimpleTableRowVue from '@/components/Ui/SimpleTableRow.vue'

export default {
  name: "RecruitVue",
  components: {
    SimpleTableRowVue
  }
}
</script>
<style lang="scss" scoped>
section {
  .wrap {
    .content {
      .occupation-name {
        text-align: center;
        padding: 10px 0;
        color: var(--white);
        background-color: var(--main);
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .detail-btn {
        display: block;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        width: 30%;
        padding: 20px 0;
        margin-top: 40px;
        margin-right: auto;
        margin-bottom: 80px;
        margin-left: auto;
        color: var(--font);
        border: 1px solid var(--black);
        background-color: var(--white);
        &:hover {
          background-color: var(--hover);
        }
        @media screen and (max-width: 767px) {
          width: 75%;
          padding: 15px 0;
          margin-top: 30px;
          margin-bottom: 40px;
        }
      }
      .mb-0 {
        margin-bottom: 0;
      }
    }
  }
}
</style>