<template>
  <section>
    <h2>
      一日の仕事の流れ
      <hr>
    </h2>
    <div class="wrap">
      <video class="flow-video" ref="player" controls>
        <source src="/video/work_video.mp4" />
        このブラウザではサポートされていません。
      </video>
      <p class="flow-video-text">戸別収集業務と搬送業務を月替わりで担当をローテーションしながら進めています。</p>
    </div>
    <div class="sub-title">
      <h3>戸別収集業務の場合</h3>
    </div>
    <div class="wrap">
      <div id="collection-operations" class="flow-contents">
        <FlowContent title="8時00分〜" content="持ち物・車両などの点検が終わりしだい会社を出発" />
        <FlowContent title="8時30分〜" content="粗大ごみの回収を開始" />
        <FlowContent title="11時30分頃" content="クリーンセンターにて荷降ろし" />
        <FlowContent title="12時00分〜" content="昼休憩（施設内にて昼食）" />
        <FlowContent title="13時00分～" content="粗大ごみ回収に出発" />
        <FlowContent title="16時00分頃" content="クリーンセンターにて荷降ろし、翌日に回収する伝票（地図）をもらって業務の確認をおこない帰社へ" />
        <FlowContent title="17時00分頃" content="会社到着・終業（※残業はほぼありません）" :arrowFlg="false" />
        <img src="@/assets/ecocreen_top_019.webp" alt="戸別収集業務のスタッフイメージ" class="top_013">
      </div>
    </div>
    <div class="sub-title">
      <h3>搬送業務の場合(東部自己搬入施設での例)</h3>
    </div>
    <div id="transportation" class="wrap">
      <div class="flow-contents">
        <FlowContent title="8時00分〜" content="持ち物・車両などの点検が終わりしだい会社を出発" />
        <FlowContent title="9時00分〜" content="施設到着後は粗大ごみをクリーンセンターまで搬送します" />
        <FlowContent title="12時00分〜" content="昼休憩（施設内にて昼食）" />
        <FlowContent title="13時00分～" content="16時頃まで粗大ごみをクリーンセンターまで搬送します" />
        <FlowContent title="17時00分頃" content="会社到着・終業（※残業はほぼありません）" :arrowFlg="false" />
        <img src="@/assets/ecocreen_top_ira007.webp" alt="戸別収集業務のスタッフイメージ" id="top_ira007" class="top_ira007 pc-only">
      </div>
      <p class="flow-description">
        ※搬入施設は東部自己搬入施設、南部自己搬入施設、北西部自己搬入施設と岐阜市内に3か所あります。また、各施設では時間や業務に多少の違いがありますが、基本的な業務は各施設に持ち込まれた粗大ごみを車両に積み込みクリーンセンターまで運搬する業務になります。
      </p>
    </div>
  </section>
</template>

<script>
import FlowContent from '@/components/Ui/FlowContent.vue';

export default {
  name: "DailyWorkFlow",
  data() {
    return {
      // データをオブジェクトとして返す
      exampleData: 'example',
    }
  },
  components: {
    FlowContent,
  },
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira007');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  .sub-title {
    margin-top: 30px;
  }
  .wrap {
    .flow-video {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    .flow-video-text {
      font-weight: 500;
      text-align: center;
      color: var(--main);
      margin-top: 30px;
      margin-bottom: 80px;
    }
    .collection-operations {
      position: relative;
    }
    .flow-contents {
      position: relative;
      margin: 50px 0;
      @media screen and (max-width: 767px) {
        margin: 25px 0;
      }
    }
    .flow-description {
      font-size: 14px;
      line-height: 1.5rem;
    }
    .top_013 {
      width: 300px;
      position: absolute;
      top: 100px;
      right: 100px;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        width: 250px;
        right: 0;
      }
      @media screen and (max-width: 767px) {
        position: static;
        width: 100%;
        margin-top: 20px;
      }
    }
    .top_ira007 {
      width: 50px;
      position: absolute;
      right: 100px;
      bottom: 10px;
      /* アニメーション用スタイル */
      opacity: 0;
      transform: scale(0.8);
      transition: opacity 0.7s ease-out, transform 0.7s ease-out;
    }
  }
}
</style>
