<template>
  <div>
    <div class="message">
      <img :src="require(`@/assets/${imgPath}`)" :alt="{ imgName }" class="message-icon" />
      <div class="message-content">
        <h3 class="message-title">{{ messageTitle }}</h3>
        <p class="message-description">{{ messageDescription }}</p>
      </div>
    </div>

    <div class="message-sp">
      <div class="title-contents">
        <img :src="require(`@/assets/${imgPath}`)" :alt="{ imgName }" class="message-icon" />
        <h3 class="message-title">{{ messageTitle }}</h3>
      </div>
      <p class="message-description">{{ messageDescription }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageContent",
  props: {
    imgPath: String,
    imgName: String,
    messageTitle: String,
    messageDescription: String
  }
}
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  margin: 20px 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
  background-color: var(--white);
  .message-icon {
    width: 20%;
    aspect-ratio: 1 / 1;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 30%;
    }
  }
  .message-content {
    width: 78%;
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 68%;
    }
    .message-title {
      font-size: 22px;
      font-weight: 500;
      text-align: start;
      color: var(--main);
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        margin: 15px 0;
      }
    }
    .message-description {
      font-size: 14px;
      color: var(--font);
    }
  }
}

.message-sp {
  display: block;
  width: 100%;
  padding: 20px 10px;
  margin: 20px 0;
  background-color: var(--white);
  @media screen and (min-width: 768px) {
    display: none;
  }
  .title-contents {
    display: flex;
    .message-icon {
      width: 30%;
      aspect-ratio: 1 / 1;
    }
    .message-title {
      font-size: 20px;
      line-height: 1.5rem;
      width: 70%;
      display: flex;
      align-items: center;
      color: var(--main);
    }
  }
  .message-description {
    font-size: 14px;
    color: var(--font);
    margin-top: 10px;
  }
}
</style>
