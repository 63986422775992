<template>
  <section>
    <h2>
      職場の雰囲気と働く仲間について
      <hr>
    </h2>
    <div class="wrap">
      <p class="community-description">
        年齢層は30代～60代と幅広く活躍しています。仕事の時間はきっちり頑張る！お休みの時は家族との時間や趣味の時間を楽しむ！という方が多いです。今、働いている先輩社員は、丁寧に教育・フォローをしてくれる優しい人ばかりです。とっても平和な雰囲気で働きやすい職場になっています。
      </p>
      <MessageContentVue 
        imgPath="ecocreen_top_kao001.webp" 
        imgName="サンプルです" 
        messageTitle="自分や家族との時間がしっかり作れます。"
        messageDescription="私は毎週末、子供とサッカーをするのが楽しみです。このお仕事は定時退社が基本で、土日もしっかり休めるので、家族と過ごす時間が増えました。年間のお休みがわかっているので、家族で旅行にも行くこともでき、とても充実した毎日を送れています。"
      />
      <MessageContentVue 
        imgPath="ecocreen_top_kao002.webp" 
        imgName="サンプルです" 
        messageTitle="未経験からでも活躍できる職場です。"
        messageDescription="入社時は運転に自信がありませんでしたが、丁寧な指導と毎日の実践を重ね、今では安全に4ｔトラックを運転できるようになりました。先輩たちがいつもそばで支えてくれるので、未経験でも安心してスキルアップが目指せます。"
      />
      <MessageContentVue 
        imgPath="ecocreen_top_kao003.webp" 
        imgName="サンプルです" 
        messageTitle="地元の方から直接感謝されることが多くて、やりがいがあります。"
        messageDescription="地域の方々から直接「ありがとう」と感謝の言葉をいただくことが多く、素直にうれしいなと思います。体を動かす仕事ではありますが、終業時刻になるといつも気持ちの良い疲労感で明日も頑張ろうという気持ちになります。"
      />
    </div>
  </section>
</template>

<script>
import MessageContentVue from '@/components/Ui/MessageContent.vue'

export default {
  name: "WorkCommunity",
  components: {
    MessageContentVue,
  }
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--sub2);
  .wrap {
    .community-description {
      font-size: 14px;
    }
  }
}
</style>
