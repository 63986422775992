import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import Driver from '@/views/Driver.vue';
import Assistant from '@/views/Assistant.vue';
import Info from '@/views/Info.vue';
import NotFound from '@/views/NotFound.vue';
import store from "@/store";
import { setTitle, setDescription } from "@/mixins/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "ドライバー・アシスタント求人｜岐阜市｜エコクリーンサービス株会社",
      description:
        "岐阜市にあるエコクリーンサービス株式会社では、粗大ごみの収集ドライバーとアシスタントスタッフを正社員にて募集しています。週休2日＋残業はほぼありませんのでプライベートも充実させながら働くことができます。毎年昇給・賞与があります。また、中型免許の取得支援もしています。未経験の方でも研修がありますので安心して働けます。",
    },
  },
  {
    path: "/driver",
    name: "driver",
    component: Driver,
    meta: {
      title: "粗大ごみ取集ドライバー求人(正社員)｜岐阜｜エコクリーンサービス株会社",
      description:
        "岐阜市にあるエコクリーンサービス株式会社では、粗大ごみの収集ドライバーを正社員にて募集しています。週休2日＋残業はほぼありませんのでプライベートも充実させながら働くことができます。毎年昇給・賞与があります。また、中型免許の取得支援もしています。未経験の方でも研修がありますので安心して働けます。",
    },
  },
  {
    path: "/assistant",
    name: "assistant",
    component: Assistant,
    meta: {
      title: "粗大ごみ取集アシスタント求人(正社員)｜岐阜｜エコクリーンサービス株会社",
      description:
        "岐阜市にあるエコクリーンサービス株式会社では、粗大ごみの収集アシスタントスタッフを正社員にて募集しています。週休2日＋残業はほぼありませんのでプライベートも充実させながら働くことができます。毎年昇給・賞与があります。また、中型免許の取得支援もしています。普通免許があれば未経験の方でも研修がありますので安心して働けます。",
    },
  },
  {
    path: "/info",
    name: "companyInfomation",
    component: Info,
    meta: {
      title: "エコクリーンサービス株式会社",
      description:
        "エコクリーンサービス株式会社の会社情報です。廃棄物の運搬事業により、 循環型社会に貢献することを目指しています。",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 NotFound｜エコクリーンサービス株会社",
      description:
        "お探しのページが見つかりませんでした。ご指定のページは存在しないか削除されています。入力したページのURLをご確認ください。",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition === true) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName("head");
  let gfjScript = document.createElement("script");
  gfjScript.type = "application/ld+json";
  if (to.meta.gfjScriptContent !== undefined) {
    gfjScript.innerHTML = to.meta.gfjScriptContent;
    documentHead[0].appendChild(gfjScript);
    //↑gfj用
  }
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router;
