<template>
  <section id="about-us">
    <h2>
      エコクリーンサービスはこんな会社です
      <hr>
    </h2>
    <div class="wrap">
      <div class="aboutus-contents">
        <div class="aboutus-img-content">
          <img src="@/assets/ecocreen_top_018.webp" alt="スタッフが作業する様子" class="aboutus-img">
          <div class="aboutus">
            <h3>未経験でも安心</h3>
            <p>未経験者でも仕事を徐々に学べるように、最初は3人のチームでスタートし、経験豊富な先輩が手厚く指導します。約3ヶ月後までに2人ペアでの作業に移行し、1年間を通じて先輩のサポートを受けながら、スキルを磨いていきます。</p>
            <img src="@/assets/ecocreen_top_maru.webp" class="top_maru">
          </div>
        </div>
        <div class="aboutus-content">
          <div class="aboutus">
            <h3>事業内容</h3>
            <p>エコクリーンサービス株式会社は、岐阜市内の家庭から出される粗大ごみの回収・運搬を行っている会社です。循環型社会に貢献することを目指しています。岐阜市からのお仕事のため、会社・事業の安定度が高いことも特徴です。</p>
            <img src="@/assets/ecocreen_top_maru.webp" class="top_maru">
          </div>
          <div class="aboutus">
            <h3>業務のやりがい</h3>
            <p>このお仕事は、世の中になくてはならないお仕事であり、地域に深く関わり、地元の人々から直接感謝されることがしばしばあります。その一言の「ありがとう」が、わたしたちの大きなやりがいとなっています。</p>
            <img src="@/assets/ecocreen_top_maru.webp" class="top_maru">
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/ecocreen_top_ira005.webp" alt="配達トラック" id="top_ira005" class="top_ira005 pc-tablet-only">
    <img src="@/assets/ecocreen_top_ira006.webp" alt="テレビを運ぶスタッフ" id="top_ira006" class="top_ira006 pc-tablet-only">
  </section>
</template>

<script>
export default {
  name: "AboutUs",
  mounted() {
    this.initIntersectionObserver();
  },
  methods: {
    initIntersectionObserver() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira005');
      targets.forEach(target => observer.observe(target));
      setTimeout(() => {
        this.initIntersectionObserver2();
      }, 1500); // 1000ミリ秒 = 1秒後にinitIntersectionObserver2を呼び出す
    },
    initIntersectionObserver2() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1;
            entry.target.style.transform = 'scale(1)';
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.transform = 'scale(0.9)';
          }
        });
      }, { threshold: 0.5 });

      const targets = document.querySelectorAll('.top_ira006');
      targets.forEach(target => observer.observe(target));
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  position: relative;
  background-color: var(--sub2);
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .wrap {
    .aboutus-contents {
      display: flex;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .aboutus-img-content {
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .aboutus-img {
          width: 90%;
          margin-right: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
          }
        }
        .aboutus {
          position: relative;
          margin-top: 50px;
          @media screen and (max-width: 767px) {
            margin-top: 25px;
          }
          h3 {
            position: inherit;
            color: var(--main);
            margin-bottom: 15px;
            z-index: 1;
            @media screen and (max-width: 767px) {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: 500;
            }
          }
          p {
            position: inherit;
            z-index: 1;
          }
          .top_maru {
            width: 80px;
            z-index: 0;
            position: absolute;
            top: -25px;
            left: -40px;
            @media screen and (max-width: 767px) {
              width: 60px;
              top: -15px;
              left: -15px;
            }
          }
        }
      }
      .aboutus-content {
        width: 50%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .aboutus {
          position: relative;
          width: 95%;
          margin-top: 20px;
          margin-bottom: 40px;
          margin-left: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
          }
          h3 {
            position: inherit;
            color: var(--main);
            margin-bottom: 15px;
            z-index: 1;
            @media screen and (max-width: 767px) {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: 500;
            }
          }
          p {
            position: inherit;
            z-index: 1;
          }
          .top_maru {
            width: 80px;
            z-index: 0;
            position: absolute;
            top: -25px;
            left: -40px;
            @media screen and (max-width: 767px) {
              width: 60px;
              top: -15px;
              left: -15px;
            }
          }
        }
      }
    }
  }
  .top_ira005 {
    width: 350px;
    position: absolute;
    top: 200px;
    left: 0;
    @media screen and (min-width: 1101px) and (max-width: 1800px) {
      width: 300px;
      top: 100px;
      left: 0;
    }
    @media screen and (max-width: 1100px) {
      width: 200px;
      top: 50px;
      left: 0;
    }
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  .top_ira006 {
    width: 400px;
    position: absolute;
    bottom: 200px;
    right: 10%;
    @media screen and (min-width: 1101px) and (max-width: 1800px) {
      bottom: 200px;
      right: 0;
      bottom: 100px;
    }
    @media screen and (max-width: 1100px) {
      width: 250px;
      bottom: 0;
      right: 5%;
    }
    /* アニメーション用スタイル */
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
}
</style>