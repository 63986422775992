<template>
  <div class="check-content">
    <img src="@/assets/ecocreen_top_check.webp" class="check-img">
    <h3 class="check-title">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "CheckContent",
  props: {
    title: String,
  }
}
</script>

<style lang="scss" scoped>
.check-content {
  display: flex;
  align-items: center;
  padding: 10px 5%;
  margin: 20px 0;
  background-color: var(--white);
  .check-img {
    width: 50px;
    margin-right: 5px;
  }
  .check-title {
    color: var(--font);
    font-weight: 400;
    line-height: 1.5rem;
  }
}
</style>
